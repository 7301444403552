import React from "react";
import { Trans, useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    return (<>
        <div className="grid grid-cols-12 text-dark pb-24 max-sm:px-6 max-sm:grid-cols-1 max-sm:pb-12 bg-disabled-5">
            <h1 className="text-heading-h1 col-start-2 col-end-11 pt-24 pb-12 max-sm:pt-12 max-sm:text-mobile-heading-h1">{t("privacy.title")}</h1>
            <div className="grid gap-6 col-start-3 col-end-11 pl-20 max-sm:pl-0">
                <div>
                    <Trans
                        components={{
                            p: <p />,
                            h3: <h3 />,
                            h4: <h4 />,
                            br: <br />,
                            ul: <ul />,
                            li: <li />,
                        }}
                    >
                        {t("privacy.web")}
                    </Trans>
                </div>
            </div>
        </div>

    </>)
};

export default PrivacyPolicy;
