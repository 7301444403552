import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoFooter } from "../images/logo-footer-app.svg";
import { ReactComponent as Copy } from "../images/copyright.svg";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    setIsMobile(screenWidth <= 767);
    setIsTablet(screenWidth >= 768 && screenWidth <= 1023);
    setIsDesktop(screenWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  
  return (
    <>
    {isMobile ? (
      <footer className="flex flex-col md:flex-row justify-center bg-neutral-100 p-5 md:py-8">
      <NavLink to="/">
        <LogoFooter />
        <div className="flex flex-row mt-2">
          <Copy />
          <span className="ml-2 text-neutral-800 text-[14px] md:text-sm mb-4">
            All rights reserved, Prodigy Padel Academy 2023
          </span>
        </div>
      </NavLink>
      <NavLink
        to="/privacy_policy"
        className="text-neutral-800 my-4 md:ml-[136px] md:mr-20  font-semibold"
      >
        {t("common.privacy_policy")}
      </NavLink>
      <NavLink
        to="/terms"
        className="text-neutral-800 my-4 md:ml-[136px] md:mr-20  font-semibold"
      >
        {t("common.terms_conditions")}
      </NavLink>
      <NavLink
        to="/data-safety"
        className="text-neutral-800 my-4 md:ml-[136px] md:mr-20  font-semibold"
      >
        {t("common.data_safety")}
      </NavLink>
    </footer>
    
    ) : (
      <footer className="flex flex-col justify-center bg-neutral-100 p-5 md:py-8 ">
      <div className="flex justify-around items-center ">
        <NavLink to="/" className="flex items-center">
          <LogoFooter />
        </NavLink>
        
        <div className="flex tablet:gap-[32px] md:gap-[90px] justify-center">
          <NavLink
            to="/privacy_policy"
            className="text-neutral-800 ml-4 font-semibold"
          >
            {t("common.privacy_policy")}
          </NavLink>
          <NavLink
            to="/terms"
            className="text-neutral-800 ml-4 font-semibold"
          >
            {t("common.terms_conditions")}
          </NavLink>
          <NavLink
            to="/data-safety"
            className="text-neutral-800 ml-4 font-semibold"
          >
            {t("common.data_safety")}
          </NavLink>
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <Copy />
        <span className="ml-2 text-neutral-800 text-[14px] md:text-sm">
          All rights reserved, Prodigy Padel Academy 2023
        </span>
      </div>
      </footer>
    )}
    </>


   
  );
};

export default Footer;
