import React, { useState } from "react";
import { Trans, useSSR, useTranslation } from "react-i18next";
import { checkLanguage } from "../utils/checkLanguage.jsx";
import { ReactComponent as EmailSent } from "../images/email-sent.svg";
import { ReactComponent as AccountIcon } from "../images/account-circle-icon.svg";
import { ReactComponent as SettingsIcon } from "../images/settings-icon.svg";
import apiFactory from "../helpers/apiFactory.js";

const DataSafety = () => {
  const { t } = useTranslation();
  const language = checkLanguage();
  const [email, setEmail] = useState("");
  const [dataView, setDataView] = useState("app");
  const [errors, setErrors] = useState({});
  const [isSubmitedd, setIsSubmitedd] = useState(false);

  const handleChangeViewData = (area) => {
    setDataView(area);
  };

  const isButtonDisabled = email.trim() === "";

  const isValidEmail = (value) => {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const sendEmailRequest = (email) => {
    const api = apiFactory();
    api
      .requestDeleteUserAccount(email)
      .then((response) => setIsSubmitedd(true));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate mandatory fields
    const errors = {};

    if (!isValidEmail(email)) {
      errors.email = "Please enter a valid email address";
    } else if (email.trim() === "") {
      errors.email = "Email is required";
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log("Form submitted successfully!");

      setEmail("");
    }
    sendEmailRequest(email);
  };

  return (
    <>
      <div className=" px-[24px] py-[40px] md:px-[264px] md:py-[112px] flex justify-center">
        {/* CONTENT */}
        <div className="w-full tablet:max-w-[786px] md:max-w-[900px]">
          <h1 className="text-[24px] md:text-[40px] font-semibold text-neutral-800">
          {t("data_safety.heading")}
          </h1>
          <div className="border-b-[2px] border-[#FFF548] bg-[#FFF548] text-[#FFF548] mb-[24px]"></div>
          <h2 className="md:text-[22px] text-[28px] font-semibold text-neutral-700 mb-[32px]">
          {t("data_safety.subtitle")}
          </h2>
          <div className="bg-primary-500  p-[16px] rounded-[4px] tablet:mb-[32px] mb-[48px]">
            <p className="text-primary-700 text-[16px] md:text-[18px] font-semibold leading-[18.96px]">
            {t("data_safety.title_text")}
            </p>
          </div>

          {/* SWITCHER */}
          <div className="flex justify-center   ">
            <button
              onClick={() => handleChangeViewData("app")}
              className={`${
                dataView === "app"
                  ? "bg-primary text-white"
                  : "bg-neutral text-primary-100"
              } w-[50%] h-full px-3 py-2 rounded-l-[100px] border border-primary font-rubik text-[16px] font-medium leading-none`}
            >
              {t("data_safety.on_app")}
            </button>
            <button
              onClick={() => handleChangeViewData("webpage")}
              className={`${
                dataView === "webpage"
                  ? "bg-primary text-white"
                  : "bg-neutral text-primary-100"
              } w-[50%] h-full px-3 py-2 rounded-r-[100px] border border-primary font-rubik text-[16px] font-medium leading-none`}
            >
              {t("data_safety.on_web")}
            </button>
          </div>
          {dataView === "app" ? (
            <div className="mt-[32px]">
              <p className="text-[16px] md:text-[18px] text-neutral-800">
              {t("data_safety.app_request_title")}
              </p>
              <ul className="list-decimal ml-[23px] text-[16px] md:text-[18px] text-neutral-800">
                <li>
                  <p className="flex flex-wrap">
                  {t("data_safety.app_request_list1")}{" "}
                    <span className="font-semibold mx-[2px] tablet:mx-1">
                      {" "}
                      {t("data_safety.app_request_list1-1")}{" "}
                    </span>{" "}
                    <AccountIcon /> {t("data_safety.app_request_list1-2")}
                  </p>
                </li>
                <li>
                  <p className="flex flex-wrap">
                  {t("data_safety.app_request_list2")}{" "}
                    <span className="font-semibold mx-[2px] tablet:mx-1">
                    {t("data_safety.app_request_list2-2")}
                    </span>{" "}
                    <SettingsIcon /> ,
                    <span> {t("data_safety.app_request_list2-3")}</span>
                  </p>
                </li>
                <li>
                  <p>
                  {t("data_safety.app_request_list3")} <span className="font-semibold">{t("data_safety.app_request_list3-1")}</span>{" "}
                  {t("data_safety.app_request_list3-2")}
                  </p>
                </li>
                <li>
                  <p>
                  {t("data_safety.app_request_list4")}{" "}
                    <span className="text-red">{t("data_safety.app_request_list4-1")}</span> {t("data_safety.app_request_list4-2")}
                  </p>
                </li>
                <li>
                  <p>
                  {t("data_safety.app_request_list5")}{" "}
                    <span className="text-red">{t("data_safety.app_request_list5-1")}</span> {t("data_safety.app_request_list5-2")}
                  </p>
                </li>
              </ul>
              <p className="text-[16px] md:text-[18px] text-neutral-800">
              {t("data_safety.app_request_text")}
              </p>
            </div>
          ) : (
            <div className="mt-[32px]">
              <p className="text-[16px] md:text-[18px] text-neutral-800">
              {t("data_safety.web_request_title")}
              </p>
              <ul className="list-decimal ml-[23px] text-[16px] md:text-[18px] text-neutral-800">
                <li>
                {t("data_safety.web_request_list1")}
                </li>
                <li>{t("data_safety.web_request_list2")}</li>
                <li>{t("data_safety.web_request_list3")}</li>
              </ul>
              <p className="text-[16px] md:text-[18px] text-neutral-800 mb-[16px] md:mb-[32px] ">
              {t("data_safety.web_request_text")}
              </p>

              <div className="px-[24px] py-[16px] md:px-[32px] md:py-[40px] rounded-[10px] border-[1px] border-[#A0BBFE]">
                {!isSubmitedd ? (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-[#5F5F5F]  caption-m-400 font-bold mb-2"
                      >
                       {t("data_safety.email")}{" "}
                        <span className="text-[#7499F5]">*</span>
                      </label>
                      <input
                        required
                        name="email"
                        value={email}
                        onChange={handleChangeEmail}
                        placeholder="john@gmail.com"
                        className="w-full h-[36.8px] bg-[#FAFAFA] text-[#9CA0AA] border border-[#EDEDEF] py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    {errors.email && (
                      <p className="text-red-500">{errors.email}</p>
                    )}

                    <div className="mt-[16px]">
                      <button
                        disabled={isButtonDisabled}
                        className={`${
                          isButtonDisabled
                            ? "bg-neutral-100"
                            : "bg-yellow text-neutral-1000"
                        } text-neutral-700 px-[56px] py-3 w-full rounded-[100px] text-[18px] font-medium leading-none `}
                      >
                        {t("data_safety.save")}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="flex flex-col justify-center items-center ">
                    <EmailSent className="w-[40px] h-[40px] md:w-[50px] md:h-[50px]" />
                    <p className="mt-[16px] md:mt-[24px] text-[18px] md:text-body-l-400 text-primary-700 font-semibold text-center">
                    {t("data_safety.submitted")}
                    </p>
                    <p className="mt-[8px] text-[16px] md:text-[18px] text-primary-700 text-center">
                    {t("data_safety.check")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center bg-primary py-10 px-4  md:py-[56px] md:px-[179px] text-white text-[22px] tablet:text-[28px] uppercase underline font-semibold">
        <a href="mailto:info@prodigypadelacademy.com">{t("common.contact")}</a>
      </div>
    </>
  );
};

export default DataSafety;
