import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { ReactComponent as IconClose } from "../images/icon-close.svg";

const PopUpCookie = () => {
  const { t } = useTranslation();
  const [close, setClose] = useState(false);

  const handlerClose = (e) => {
    e.preventDefault();
    setClose(!close);
  }

  return (
    <div className={`fixed duration-100 text-primary-700 text-body-m-700 max-sm:text-mobile-body-m-700 rounded-xl flex flex-col bg-primary-500 pb-10 shadow-lg bottom-0 z-50 animate-fade-up  sm:animate-fade-left sm:w-1/3 sm:bottom-8 sm:right-8 p-6  ${close ? "hidden" : ""}`}>
      <div className="flex justify-end pb-3">
        <button onClick={() => { setClose(!close) }} className=""><IconClose /></button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <h3 className="pb-3 text-heading-display">{t("cookie.title_popup")}</h3>
        <div className=" text-body-m-400 items-center text-center ">
          <Trans
            components={{
              span: <span />,
              a: <a className="text-info font-bold" />,
              p: <p />,
            }}
          >
            {t("cookie.description_popup")}
          </Trans>
          <div className="flex flex-col sm:grid pt-6 sm:grid-cols-2 gap-2 ">
            <button
              className="bg-neutral border-2 hover:bg-neutral hover:border-primary-700 border-primary-700 hover:text-primary-700 text-primary-700 rounded-full py-3 w-full transition duration-300 text-mobile-item-m"
              onClick={(e) => handlerClose(e)}
            >
              {t("cookie.button_necessary")}
            </button>
            <button
              className="bg-primary-700 border-2 hover:bg-primary-700 hover:border-primary-700 border-primary-700 hover:text-neutral text-neutral rounded-full py-3 w-full transition duration-300 text-mobile-item-m"
              onClick={(e) => handlerClose(e)}
            >
              {t("cookie.button_accept")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpCookie;