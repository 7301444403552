import React from "react";
import { ReactComponent as Logo } from "../images/logo-app.svg";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header className="flex justify-center bg-primary py-4 px-6 md:py-8 md:px-10">
      <NavLink to="/">
        <Logo />
      </NavLink>
    </header>
  );
};

export default Header;
